var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "footer" }, [
    _c("div", { staticClass: "footer_core" }, [
      _c("div", { staticClass: "guidance" }, [
        _c("h3", { staticStyle: { "margin-top": "24px" } }, [
          _vm._v(_vm._s(_vm.$t("Footer.GuidanceContent5")))
        ]),
        _c("a", { attrs: { href: "javaScript:;" } }, [
          _vm._v(_vm._s(_vm.$t("Footer.GuidanceContent6")))
        ])
      ]),
      _c("div", { staticClass: "guidances" }, [
        _c("h3", [_vm._v(_vm._s(_vm.$t("Footer.Overseas")))]),
        _c("div", { staticClass: "box_warp" }, [
          _c(
            "ul",
            { staticClass: "left" },
            _vm._l(_vm.mediaList, function(unit, index) {
              return _c("li", { key: "unit-list-" + index }, [
                _c(
                  "a",
                  { ref: "nofollow", refInFor: true, attrs: { href: unit[1] } },
                  [_vm._v(_vm._s(unit[0]))]
                )
              ])
            }),
            0
          )
        ])
      ]),
      _c("div", { staticClass: "Contactus" }, [
        _c("h3", [_vm._v(_vm._s(_vm.$t("Footer.Contact")))]),
        _c("div", { staticClass: "warp_conta" }, [
          _c("div", { staticClass: "box_Warpw" }, [
            _c("div", { staticClass: "Telephone   " }, [
              _c("img", { attrs: { src: "/images/dianhau.png", alt: "" } }),
              _c("p", [
                _vm._v(
                  _vm._s(
                    _vm.$i18n.locale === "en"
                      ? "+86 181-9407-8150"
                      : "18194078150"
                  ) + " "
                )
              ])
            ]),
            _vm._m(0),
            _vm._m(1),
            _vm._m(2),
            _vm._m(3),
            _vm._m(4)
          ]),
          _c("div", { staticClass: "QRcode" }, [
            _vm._m(5),
            _c("p", {
              domProps: { innerHTML: _vm._s(_vm.$t("Footer.Account")) }
            })
          ])
        ])
      ])
    ]),
    _c("div", { staticClass: "footer_buttom" }, [
      _vm._v("\n            " + _vm._s(_vm.$t("Footer.FooterButtom")) + " "),
      _c("a", { attrs: { href: "http://beian.miit.gov.cn/" } }, [
        _vm._v(_vm._s(_vm.$t("Footer.FooterButtom2")))
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "envelope common" }, [
      _c("img", { attrs: { src: "/images/xingfneg.png", alt: "" } }),
      _c("p", [_vm._v("info@conmart.cn")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "FlyingPigeon common" }, [
      _c("img", { attrs: { src: "/images/feige.png", alt: "" } }),
      _c("p", [_vm._v("@conmartofficial")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mailbox common" }, [
      _c("img", { attrs: { src: "/images/feeckboos.png", alt: "" } }),
      _c("p", [_vm._v("@Conmart.cn")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "Conmart common" }, [
      _c("img", { attrs: { src: "/images/in.png", alt: "" } }),
      _c("p", [_vm._v("Conmart")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "Official common" }, [
      _c("img", { attrs: { src: "/images/bofang.png", alt: "" } }),
      _c("p", [_vm._v("Conmart Official")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        staticStyle: { width: "128px" },
        attrs: { src: "/images/weixin.jpg", alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="footer">
        <div class="footer_core">
            <div class="guidance">
<!--                <h3>{{$t('Footer.Guidance')}}</h3>-->
<!--                <a href="javaScript:;">{{$t('Footer.GuidanceContent0')}}</a>-->
               <!-- <h3 style="margin-top:10px">{{$t('Footer.GuidanceContent1')}}</h3>
                <a href="javaScript:;">{{$t('Footer.GuidanceContent2')}}</a> -->
<!--                <a href="javaScript:;">{{$t('Footer.GuidanceContent3')}}</a>-->
                <h3 style="margin-top:24px">{{$t('Footer.GuidanceContent5')}}</h3>
                <a href="javaScript:;">{{$t('Footer.GuidanceContent6')}}</a>
            </div>
            <div class="guidances">
                <h3>{{$t('Footer.Overseas')}}</h3>
                <div class="box_warp">
                    <ul class="left">
                        <li v-for="(unit,index) in mediaList" :key="`unit-list-${index}`">
                            <a :href="unit[1]" ref="nofollow">{{unit[0]}}</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="Contactus">
                <h3>{{$t('Footer.Contact')}}</h3>
                <div class="warp_conta">
                    <div class="box_Warpw">
                        <div class="Telephone   ">
                            <img src="/images/dianhau.png" alt="">
                            <p>{{$i18n.locale === 'en' ? '+86 181-9407-8150':'18194078150'}} </p>
                        </div>
                        <div class="envelope common">
                            <img src="/images/xingfneg.png" alt="">
                            <p>info@conmart.cn</p>
                        </div>
                        <div class="FlyingPigeon common">
                            <img src="/images/feige.png" alt="">
                            <p>@conmartofficial</p>
                        </div>
                        <div class="mailbox common">
                            <img src="/images/feeckboos.png" alt="">
                            <p>@Conmart.cn</p>
                        </div>
                        <div class="Conmart common">
                            <img src="/images/in.png" alt="">
                            <p>Conmart</p>
                        </div>
                        <div class="Official common">
                            <img src="/images/bofang.png" alt="">
                            <p>Conmart Official</p>
                        </div>
                    </div>
                    <div class="QRcode">
                        <div>
                            <img style="width: 128px;" src="/images/weixin.jpg" alt="">
                        </div>
                        <p v-html="$t('Footer.Account')"></p>
                    </div>
                </div>

            </div>
        </div>
        <div class="footer_buttom">
            {{$t('Footer.FooterButtom')}} <a href="http://beian.miit.gov.cn/">{{$t("Footer.FooterButtom2")}}</a>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'FooterComponent',
        data() {
            return {
                mediaList : [
                    ['NBM & CW','#'],
                    ['Philippine Resources Journal','#'],
                    ['CPI','#'],
                    ['TEXHO Magazine','#'],
                    ['Via Mobilis','#'],
                    ['GineersNow','#'],
                    ['慧聪工程机械网','http://www.cm.hc360.com/'],
                    ['中国路面机械网','http://www.zcwz.com/'],
                    ['铁甲网','https://www.cehome.com/'],
                    ['中国工程机械商贸网','#'],
                    ['第一工程机械网','https://www.d1cm.com/'],
                    ['工程机械在线','http://www.cmol.com/'],
                    ['环球破碎机网','http://www.ycrusher.com/'],
                    ['机电之家','#'],
                    ['矿道网','#'],
                    ['砼商网','http://www.ccmn.net/'],
                    ['五湖工程机械网','#'],
                    ['挖掘机网','http://www.wjjw.cn/'],
                    ['瀛丰工程机械网','https://www.yingfeng365.com/'],
                    ['液压网','http://www.zgyyw.cc/'],
                    ['中华轴承网','http://www.zcwz.com/'],
                ],
            }
        }
    }
</script>

<style lang="scss" scoped>
    .footer {
        width: 100%;
        background: rgba(78, 78, 78, 1);

        .footer_core {
            min-height: 379px;
            $width: px2rem((374 + 38) * 2);
            width: calc(#{$width} + 496px);
            margin: auto;
            display: flex;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            padding-top: 54px;
            box-sizing: border-box;

            .guidance {
                // margin-right: 216px;
                margin-top: 4px;
                width: 46%;

                h3 {
                    font-size: 14px;
                    font-weight: 500;
                    color: rgba(255, 255, 255, 1);
                    margin-bottom: 15px;
                }

                a {
                    display: block;
                    font-size: 12px;
                    font-weight: 400;
                    color: rgba(204, 204, 204, 1);
                    line-height: 14px;
                    padding: 6px 0;
                }
            }

            .guidances {
                width: 82%;
                box-sizing: border-box;

                h3 {

                    font-size: 16px;
                    font-weight: 600;
                    color: rgba(255, 255, 255, 1);
                    height: 30px;
                    width: 100%;
                    // line-height:0px;
                    border-bottom: 1px dashed rgba(255, 255, 255, 1);
                    margin-bottom: 6px;
                    margin-left: 8px;

                }

                .box_warp {
                    width: 100%;

                    ul {
                        overflow: hidden;
                        padding-left: 8px;

                        li {
                            float: left;
                            width: 50%;

                            a {
                                font-size: 12px;
                                font-weight: 400;
                                color: rgba(204, 204, 204, 1);
                                line-height: 23px;
                            }
                        }
                    }

                    .right {
                        margin-left: 30px;
                    }
                }
            }

            .Contactus {
                margin-left: 63px;
                display: block;
                min-width: 30%;
                position: relative;

                h3 {
                    font-size: 16px;
                    font-weight: 600;
                    color: rgba(255, 255, 255, 1);
                    height: 30px;
                    // line-height:0px;
                    border-bottom: 1px dashed rgba(255, 255, 255, 1);
                    margin-bottom: 6px;
                }

                .box_Warpw {
                    div {
                        p {
                            font-size: 12px;
                            font-family: Arial;
                            font-weight: 400;
                            color: rgba(204, 204, 204, 1);
                            line-height: 10px;
                        }
                    }

                    .Telephone {
                        height: 18px;
                        display: flex;
                        display: -webkit-box;
                        display: -moz-box;
                        display: -ms-flexbox;
                        display: -webkit-flex;
                        align-items: center;
                        margin-top: 11px;

                        img {
                            width: 17px;
                            height: 18px;
                        }

                        p {
                            font-size: 24px;
                            font-weight: 500;
                            color: rgba(255, 255, 255, 1);
                            margin-left: 11px;
                        }
                    }

                    .envelope {
                        height: 20px;
                        display: flex;
                        display: -webkit-box;
                        display: -moz-box;
                        display: -ms-flexbox;
                        display: -webkit-flex;
                        align-items: center;
                        margin-top: 11px;

                        p {
                            margin-left: 11px;
                        }
                    }

                    .Official {
                        height: 20px;
                        display: flex;
                        display: -webkit-box;
                        display: -moz-box;
                        display: -ms-flexbox;
                        display: -webkit-flex;
                        align-items: center;
                        margin-top: 11px;

                        p {
                            margin-left: 11px;
                        }
                    }

                    .FlyingPigeon {
                        height: 20px;
                        display: flex;
                        display: -webkit-box;
                        display: -moz-box;
                        display: -ms-flexbox;
                        display: -webkit-flex;
                        align-items: center;
                        margin-top: 11px;

                        p {
                            margin-left: 11px;
                        }
                    }

                    .mailbox {
                        height: 20px;
                        display: flex;
                        display: -webkit-box;
                        display: -moz-box;
                        display: -ms-flexbox;
                        display: -webkit-flex;
                        align-items: center;
                        margin-top: 11px;

                        p {
                            margin-left: 11px;
                        }
                    }

                    .Conmart {
                        height: 20px;
                        display: flex;
                        display: -webkit-box;
                        display: -moz-box;
                        display: -ms-flexbox;
                        display: -webkit-flex;
                        align-items: center;
                        margin-top: 11px;

                        p {
                            margin-left: 11px;
                        }
                    }
                }
            }

            .QRcode {
                position: absolute;
                margin-top: 16%;
                margin-left: 0%;

                right: 0;
                bottom: 5px;

                @include md {
                    position: relative;
                    bottom: 0;
                }

                p {
                    text-align: center;
                    font-size: 12px;
                    font-weight: 400;
                    color: rgba(204, 204, 204, 1);
                    margin-top: 8px;
                }
            }
        }

        .footer_buttom {
            text-align: center;
            padding: 25px 0;
            box-sizing: border-box;
            background: rgba(56, 56, 56, 1);
            line-height: 20px;
            font-size: 12px;
            font-weight: 400;
            color: rgba(204, 204, 204, 1);
        }
    }

    .warp_conta {
        position: relative;
        overflow: hidden;
        padding-bottom: 30px;

        > div {
            float: left;
        }
    }

    @include md {
        .footer {
            .footer_core {
                width: 9rem;
                flex-wrap: wrap;

                .guidance, .guidances {
                    width: 50%;
                    max-width: 50%;
                    flex-basis: 50%;
                }

                .Contactus {
                    margin: px2rem(60) 0 0 0;
                }
            }
        }
    }

    @include sm {

        .footer {
            .footer_core {
                width: 9rem;

                .guidance, .guidances {
                    width: 100%;
                    max-width: 100%;
                    flex-basis: 100%;
                    margin: px2rem(80) 0 0 0;
                }

                .Contactus {
                    margin: px2rem(80) 0 0 0;
                }
            }
        }
    }
</style>
